import { mount } from '@vue/test-utils';
import Set from './Set.vue';

function mountComponent(type, media) {
  return mount(Set, {
    propsData: { media },
    stubs: {
      HsIcon: true,
      HsButtonCircle: true,
    },
    mocks: {
      $t: key => key,
    },
  });
}

describe('Set', () => {
  describe('render types', () => {
    [
      [
        'text',
        {
          type: 'Text',
        },
      ],
      [
        'image',
        {
          type: 'Image',
        },
      ],
      [
        'ebook',
        {
          type: 'Document',
        },
      ],
      [
        'file',
        {
          type: 'Document',
        },
      ],
      [
        'video',
        {
          type: 'Video',
          host: 'Youtube',
          status: 'pending',
        },
      ],
      [
        'video',
        {
          type: 'Video',
          host: 'Vimeo',
          status: 'pending',
        },
      ],
      [
        'video',
        {
          type: 'Video',
          host: 'SparkVideos',
          status: 'pending',
        },
      ],
    ].forEach(item => {
      const [type, media] = item;
      it(`should render type ${type} ${media.host || ''}`, () => {
        expect(() => mountComponent(type, media)).not.toThrow();
      });
    });
  });
});
